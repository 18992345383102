.login-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 90vh;
}
details {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
}

#login-btn {
  background-color: #543dae;
  border-color: #543dae;
}

#reset-password-btn {
  background-color: #ffd604;
  border-color: #ffd604;
  text-decoration: none;
}

summary {
  font-weight: bold;
  cursor: pointer;
}

.btn-calculator {
  background-color: black !important;
  color: white !important;
}

.jobdone-calculator {
  font-family: sans-serif;
  font-size: 16px;
  color: #ffffff;
  margin-bottom: 30px;
  background-color: black;
}

.job-header {
  font-weight: bold;
  margin-bottom: 10px;
}

.job-list {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.job-item {
  margin-right: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.job-id {
  font-weight: bold;
  margin-right: 5px;
}

.job-price {
  font-weight: bold;
}

.total-earnings {
  font-weight: bold;
  color: #0a7baf;
}

.top-nav-dropdown-item:hover,
.dropdown-item:hover {
  background-color: green;
}
